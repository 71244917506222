<template>
    <b-table-simple class="procedures-table">
      <tbody>
        <tr v-for="procedure in procedures" :key="procedure.id">
          <td>
            <div v-if="procedure.clinic_procedure">
              <div class="procedure-prefix">
                <span class="icon-box">
                  <v-procedure-icon v-if="procedure.clinic_procedure.type === 'PROCEDURE'" class="icon stroke procedure-icon" />
                  <v-telemedicine-icon v-if="procedure.clinic_procedure.type === 'TELEMEDICINE'" class="icon stroke procedure-icon" />
                  <v-appointment-icon v-if="procedure.clinic_procedure.type === 'APPOINTMENT'" class="icon stroke procedure-icon" />
                  <v-return-icon v-if="procedure.clinic_procedure.type === 'RETURN'" class="icon stroke procedure-icon" />
                  <v-exam-icon v-if="procedure.clinic_procedure.type === 'EXAM'" class="icon stroke procedure-icon" />
                  <v-surgical-icon v-if="procedure.clinic_procedure.type === 'SURGICAL'" class="icon stroke procedure-icon" />
                </span>
                <span>{{ parseProcedureType(procedure.clinic_procedure.type) }}</span>
                <div class="procedure-pipe" />
              </div>
              <span>{{ procedure.clinic_procedure.name }}</span>
            </div>
            <div v-else>
              {{ procedure }}
            </div>
          </td>
          <td>
            <div class="procedure-quantity">
              {{normalizeProductNumber(procedure.quantity, 2)}}
            </div>
          </td>
          <td>
            <div class="procedure-value">
              {{parseNumberToMoney(procedure.amount)}}
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </template>
  <script>
  import { normalizeProductNumber } from '@/utils/product-helper';
  import { parseNumberToMoney } from '@/utils/moneyHelper';
  
  export default {
    props: {
      procedures: { type: Array, required: true },
    },
    components: {
      'v-procedure-icon': () => import('@/assets/icons/procedure.svg'),
      'v-telemedicine-icon': () => import('@/assets/icons/telemedicine.svg'),
      'v-appointment-icon': () => import('@/assets/icons/appointment.svg'),
      'v-return-icon': () => import('@/assets/icons/return.svg'),
      'v-exam-icon': () => import('@/assets/icons/exam.svg'),
      'v-surgical-icon': () => import('@/assets/icons/surgical.svg'),
    },
    methods: {
      normalizeProductNumber,
      parseNumberToMoney,
      parseProcedureType(type) {
        switch(type) {
          case 'PROCEDURE':
            return 'Procedimento';
          case 'TELEMEDICINE':
            return 'Telemedicina';
          case 'APPOINTMENT':
            return 'Presencial';
          case 'RETURN':
            return 'Retorno';
          case 'EXAM':
            return 'Exame';
          case 'SURGICAL':
            return 'Centro Cirúrgico';
          default:
            return type;
        }
      },
    }
  }
  </script>
  <style lang="scss" scoped>
    .procedure-prefix {
      display: inline-flex;
      align-items: center;
      color: var(--blue-500);
    }
    .procedure-pipe {
      height: 15px;
      width: 2px;
      background-color: var(--blue-500);
      margin: 0 5px;
      border-radius: 50%;
    }
    .procedure-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      fill: var(--blue-500);
    }
    .procedures-table {
      margin-top: -20px;
      margin-bottom: 20px;
      td {
        padding: 20px 0 !important;
        border-top: none !important;
        border-bottom: 1px solid #dee2e6;
        vertical-align: middle !important;
      }
    }
    .procedure-quantity {
      font-weight: 600;
      font-size: 16px;
      font-family: 'Nunito Sans';
      padding: 0 20px;
      color: var(--type-placeholder);
    }
    .procedure-value {
      font-weight: 600;
      font-size: 16px;
      font-family: 'Nunito Sans';
      color: var(--type-active);
    }
  </style>
  